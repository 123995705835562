import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { areAssetsPreloaded, preloadAssets } from "../assets"

import PreloaderFrame1 from "!!url-loader!../images/gorgeous-loading-f1.gif"
import PreloaderAnimation from "../images/gorgeous-loading.gif"

import "./preloader.scss"

const Preloader = () => {
  let contentAssetData = useStaticQuery(graphql`
    query PreloadedVideoAssets {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              videos {
                id
                vimeoVideo {
                  remoteImage {
                    childImageSharp {
                      resize(width: 640) {
                        src
                        width
                        height
                      }
                    }
                  }
                }
                thumbnail {
                  scale
                  xPos
                  yPos
                }
              }
            }
          }
        }
      }
    }
  `)
  let [assetsLoaded, setAssetsLoaded] = useState(areAssetsPreloaded())
  useEffect(() => {
    !areAssetsPreloaded() &&
      preloadAssets(contentAssetData).then(() => setAssetsLoaded(true))
  }, [contentAssetData])

  return (
    <div className={`preloader ${!assetsLoaded ? "assetsLoading" : ""}`}>
      <img className="preloaderImage" src={PreloaderFrame1} alt="Cat" />
      <img className="preloaderAnimation" src={PreloaderAnimation} alt="Cat" />
    </div>
  )
}

export default Preloader
