import homeBg from "./images/backgroundHome.webp"
import homeBgNoWebp from "./images/backgroundHome.png"
import homeBgTV from "./images/backgroundHomeTV.webp"
import homeBgTVNoWebp from "./images/backgroundHomeTV.png"
import homeBgBlurV from "./images/backgroundHome--blurV.webp"
import homeBgBlurVNoWebp from "./images/backgroundHome--blurV.jpg"
import homeBgBlurH from "./images/backgroundHome--blurH.webp"
import homeBgBlurHNoWebp from "./images/backgroundHome--blurH.jpg"
import archiveBg from "./images/backgroundArchive.webp"
import archiveBgNoWebp from "./images/backgroundArchive.jpg"
import archiveBgBlurV from "./images/backgroundArchive--blurV.webp"
import archiveBgBlurVNoWebp from "./images/backgroundArchive--blurV.jpg"
import archiveBgBlurH from "./images/backgroundArchive--blurH.webp"
import archiveBgBlurHNoWebp from "./images/backgroundArchive--blurH.jpg"
import awardsBg from "./images/backgroundAwards.webp"
import awardsBgNoWebp from "./images/backgroundAwards.jpg"
import awardsBgBlurV from "./images/backgroundAwards--blurV.webp"
import awardsBgBlurVNoWebp from "./images/backgroundAwards--blurV.jpg"
import contactBg from "./images/backgroundContact.webp"
import contactBgNoWebp from "./images/backgroundContact.jpg"
import contactBgBlurH from "./images/backgroundContact--blurH.webp"
import contactBgBlurHNoWebp from "./images/backgroundContact--blurH.jpg"
import directorsBg from "./images/backgroundDirectors.webp"
import directorsBgNoWebp from "./images/backgroundDirectors.jpg"
import directorsBgBlurV from "./images/backgroundDirectors--blurV.webp"
import directorsBgBlurVNoWebp from "./images/backgroundDirectors--blurV.jpg"
import directorsFg from "./images/foregroundDirectors.webp"
import directorsFgNoWebp from "./images/foregroundDirectors.png"
import wallpaperBg from "./images/wallpaper.jpg"
import Polaroid from "./images/polaroid.webp"
import PolaroidNoWebp from "./images/polaroid.png"

import chrisFrame from "./images/chrisFrame.webp"
import chrisFrameNoWebp from "./images/chrisFrame.png"
import anastasiaFrame from "./images/anastasiaFrame.webp"
import anastasiaFrameNoWebp from "./images/anastasiaFrame.png"
import chrisPoster from "./images/chrisKitten.webp"
import chrisPosterNoWebp from "./images/chrisKitten.png"
import anastasiaPoster from "./images/anastasiaKitten.webp"
import anastasiaPosterNoWebp from "./images/anastasiaKitten.png"

import chrisKitten from "./videos/chrisKitten.mp4"
import anastasiaKitten from "./videos/anastasiaKitten.mp4"

const IMAGE_URLS = [
  homeBg,
  homeBgTV,
  homeBgBlurV,
  homeBgBlurH,
  archiveBg,
  archiveBgBlurV,
  archiveBgBlurH,
  awardsBg,
  awardsBgBlurV,
  contactBg,
  contactBgBlurH,
  directorsBg,
  directorsBgBlurV,
  directorsFg,
  wallpaperBg,
  Polaroid,
  chrisFrame,
  anastasiaFrame,
  chrisPoster,
  anastasiaPoster,
]
const IMAGE_URLS_NO_WEBP = [
  homeBgNoWebp,
  homeBgTVNoWebp,
  homeBgBlurVNoWebp,
  homeBgBlurHNoWebp,
  archiveBgNoWebp,
  archiveBgBlurVNoWebp,
  archiveBgBlurHNoWebp,
  awardsBgNoWebp,
  awardsBgBlurVNoWebp,
  contactBgNoWebp,
  contactBgBlurHNoWebp,
  directorsBgNoWebp,
  directorsBgBlurVNoWebp,
  directorsFgNoWebp,
  wallpaperBg,
  PolaroidNoWebp,
  chrisFrameNoWebp,
  anastasiaFrameNoWebp,
  chrisPosterNoWebp,
  anastasiaPosterNoWebp,
]
const VIDEO_URLS = [chrisKitten, anastasiaKitten]

let preloadPromise,
  preloaded = false,
  preloadCallbacks = [],
  videoThumbnails = new Map(),
  videoAssetUrls = new Map()

export function areAssetsPreloaded() {
  return preloaded
}

export function onPreload(callback) {
  if (preloadPromise) {
    preloadPromise.then(callback)
  } else {
    preloadCallbacks.push(callback)
  }
}

export function preloadAssets(contentAssets) {
  if (!preloadPromise) {
    const imageUrls = window.Modernizr.webp ? IMAGE_URLS : IMAGE_URLS_NO_WEBP
    preloadPromise = new Promise(res => setTimeout(res, 500))
      .then(() =>
        Promise.all(
          imageUrls
            .map(preloadImage)
            .concat(preloadVideos())
            .concat([preloadContentAssets(contentAssets)])
        )
      )
      .then(() => {
        preloaded = true
        for (let cb of preloadCallbacks) cb()
      })
  }
  return preloadPromise
}

export function getVideoThumbnail(videoId) {
  return videoThumbnails.get(videoId)
}

export function getVideoAssetUrl(videoUrl) {
  return videoAssetUrls.get(videoUrl)
}

function preloadImage(url) {
  return new Promise((res, rej) => {
    let image = new Image()
    image.onload = () => res()
    image.onerror = () => rej()
    image.src = url
  })
}

function preloadVideos() {
  return VIDEO_URLS.map(vUrl =>
    fetch(vUrl)
      .then(vUrl => vUrl.blob())
      .then(vBlob => {
        videoAssetUrls.set(vUrl, URL.createObjectURL(vBlob))
      })
  )
}

function preloadContentAssets(contentAssetData) {
  let vimeoVideos = []
  let sizes = new Map()
  for (let edge of contentAssetData.allMarkdownRemark.edges) {
    for (let video of edge.node.frontmatter.videos) {
      if (video.vimeoVideo) {
        vimeoVideos.push({
          videoId: video.id,
          vimeoVideo: video.vimeoVideo,
        })
      }
      if (video.thumbnail) {
        sizes.set(video.id, video.thumbnail)
      }
    }
  }

  console.log(vimeoVideos)

  return Promise.all(
    vimeoVideos.map(({ videoId, vimeoVideo }) => {
      return fetch(vimeoVideo.remoteImage.childImageSharp.resize.src)
        .then(res => res.blob())
        .then(res => {
          let url = URL.createObjectURL(res)
          let imgWidth = vimeoVideo.remoteImage.childImageSharp.resize.width
          let imgHeight = vimeoVideo.remoteImage.childImageSharp.resize.height
          let size = sizes.get(+videoId)
          let scalePct = 100
          if (size && size.scale) {
            scalePct = +size.scale
          }
          let xPos, yPos
          if (size && typeof size.xPos === "number") {
            xPos = size.xPos
          }
          if (size && typeof size.yPos === "number") {
            yPos = size.yPos
          }
          videoThumbnails.set(videoId, {
            url,
            imgWidth,
            imgHeight,
            imgScale: scalePct / 100,
            xPos,
            yPos,
          })
        })
      // return new Promise(res => {
      //   let img = new Image()
      //   img.crossOrigin = "Anonymous"
      //   img.onload = () => {

      // let size = sizes.get(+videoId)
      // let scalePct = 100
      // if (size && size.scale) {
      //   scalePct = +size.scale
      // }
      // let hScale = (thumbHeight * scalePct) / 100 / img.height
      // let xPos = (img.width - thumbWidth / hScale) / 2,
      //   yPos = (img.height - thumbHeight / hScale) / 2
      // if (size && typeof size.xPos === "number") {
      //   xPos = size.xPos
      // }
      // if (size && typeof size.yPos === "number") {
      //   yPos = size.yPos
      // }

      // let blob = new Blob([img], { type: "image/jpeg" })
      // let url = URL.createObjectURL(blob)
      // videoThumbnails.set(videoId, url)

      // ctx.drawImage(
      //   img,
      //   xPos,
      //   yPos,
      //   thumbWidth / hScale,
      //   thumbHeight / hScale,
      //   0,
      //   0,
      //   thumbWidth,
      //   thumbHeight
      // )
      // cnvs.toBlob(blob => {
      //   videoThumbnailUrls.set(videoId, URL.createObjectURL(blob))
      //   res()
      // }, "image/jpeg")
      //   res()
      // }
      // img.src = vimeoVideo.remoteImage.publicURL
      // })
    })
  )
}
