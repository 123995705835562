import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import Preloader from "../components/preloader"

import "./layout.scss"

const Layout = ({ page, transitionStatus, children }) => {
  return (
    <main className={`${page} ${transitionStatus}`}>
      <Helmet>
        <script src="/modernizr-webp.js"></script>
      </Helmet>
      {children}
      <Preloader />
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
